import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import { graphql } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { addAuthentication } from '../../../components/addAuthentication';
import {
  DashboardPageHeading,
  PaymentMethod,
  PlanInformation,
} from '../../../components/dashboard';
import { DashboardLayout } from '../../../components/layout';
import { SplashScreen } from '../../../components/misc';
import './scss/PlanAndPaymentPage.scss';
import { StateContext } from '../../../lib/providers';
import { isPremiumPlan } from '../../../lib/utils';
import { useScript } from '../../../util/useScript';

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default addAuthentication(() => {
  const state = useContext(StateContext);
  const { user } = state || {};
  const { subscription } = user || {};
  const [recurlyReady, setRecurlyReady] = useState(false);
  const recurlyStatus = useScript('https://js.recurly.com/v4/recurly.js');
  const premiumPlan = subscription
    ? isPremiumPlan(subscription.plan.code)
    : null;

  if (subscription || premiumPlan) {
    console.log('PlanAndPaymentPage', { subscription, premiumPlan });
  }

  useEffect(() => {
    setRecurlyReady(recurlyStatus === 'ready');
  }, [recurlyStatus, recurlyReady]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amwell Private Practice | Dashboard - Plan</title>
      </Helmet>
      <DashboardLayout>
        <div className="PlanAndPaymentPage">
          <DashboardPageHeading
            heading={'Plan & Payment'}
            links={[{ label: 'My Plan & Payment', to: '/dashboard/plan' }]}
          />
          <div className="PlanAndPaymentPage-container">
            {recurlyReady ? (
              <>
                <RecurlyProvider
                  publicKey={process.env.GATSBY_RECURLY_PUBLIC_KEY}
                >
                  <Elements>
                    <PlanInformation />
                    {subscription && premiumPlan ? <PaymentMethod /> : null}
                  </Elements>
                </RecurlyProvider>
              </>
            ) : (
              <SplashScreen />
            )}
          </div>
        </div>
      </DashboardLayout>
    </>
  );
});
